


































































import { computed, defineComponent, PropType } from '@nuxtjs/composition-api';
import CtaPartial from '@theme/components/molecules/CtaPartial.vue';
import { RenderPoiSource, ImageHolderInterface, FeatureItemInterface, CtaInterface } from '@amplience/types';

export default defineComponent({
  name: 'ShopsFeatureBlock',
  components: {
    CtaPartial,
  },
  props: {
    title: {
      type: String,
      default: ''
    },
    content: {
      type: Array as PropType<FeatureItemInterface[]>,
      default: () => []
    },
  },
  setup(props) {
    const ctaParams = (cta: CtaInterface) => {
      return {
        linkType: cta.linkType,
        title: cta.title || '',
        url: cta.url || '',
        type: cta.type || 'Primary',
        newWindow: !!(cta.newWindow),
        noFollow: !!(cta.noFollow),
        trackingQuery: cta.trackingQuery || '',
      }
    };

    return {
      ctaParams,
      isAddress: (feature: any): boolean => feature.address && feature.address.length,
      isBackgroundImage: (image: ImageHolderInterface): boolean => !!image?.poi?.image,
      isCallToAction: (item: FeatureItemInterface): boolean => !!(item.cta?.url && item.cta?.type && item.cta?.title),
      sources: computed(() => ([
        {
          aspect: '1.9:1.1',
          height: 224,
          media: '(max-width: 767px)',
        },
        {
          aspect: props.content && props.content.length === 2 ? '13:8' : '29:17',
          height: props.content && props.content.length === 2 ? 320 : 204,
          media: '(min-width: 768px)',
        },
      ] as RenderPoiSource[])),
    };
  }
});
